import React, { useState } from 'react';
import {
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import languageTag from '../../../lib/languageTag';

export const CreateButton = ({ label, onPress, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="btn-rounded waves-effect waves-light ml-3 mr-2 btn btn-success"
      onClick={() => onPress()}
      disabled={disabled}>
      <i className="mdi mdi-plus mr-1"></i>
      {t(label)}
    </button>
  );
};

export const CountRowTablePlacement = (props) => (
  <div id="row-counter-table" className="flex-fill"></div>
);

export const ExportButtonPlacement = (props) => (
  <div id="export-button-table"></div>
);

export const RefreshButton = ({ label, onPress, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      disabled={disabled}
      className="btn btn-sm btn-outline-dark waves-effect"
      style={{ marginLeft: '10px' }}
      onClick={onPress}>
      {disabled ? (
        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
      ) : (
        <i className="bx bx-refresh font-size-16 align-middle mr-2"></i>
      )}
      {t(label || 'Refresh')}
    </button>
  );
};

export const ViewButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'view_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-spreadsheet"></i>
        <UncontrolledTooltip placement="top" target={'view_' + row.id}>
          {t('View')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const EditButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'edit_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-edit"></i>
        <UncontrolledTooltip placement="top" target={'edit_' + row.id}>
          {t('Edit')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const DeleteButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'delete_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-trash"></i>
        <UncontrolledTooltip placement="top" target={'delete_' + row.id}>
          {t('Delete')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const OpenUrlButton = ({ row, icon, url, label }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link
        to="#"
        id={'open_url_' + row.id}
        onClick={() => window.open(url || row.url, '_blank')}>
        <i className={`${icon || 'bx bx-link-external'}`}></i>
        <UncontrolledTooltip placement="top" target={'open_url_' + row.id}>
          {t(label || 'Open')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const ListenButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'listenPodcast_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-play-circle"></i>
        <UncontrolledTooltip placement="top" target={'listenPodcast_' + row.id}>
          {t('Listen Spoken Article')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const SynthesizeButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link
        to="#"
        id={'synthesizePodcast_' + row.id}
        onClick={() => onPress(row)}>
        <i className="bx bx-headphone"></i>
        <UncontrolledTooltip
          placement="top"
          target={'synthesizePodcast_' + row.id}>
          {t('Synthesize Article')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const PublishButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  const config = row.published
    ? { icon: 'bx bx-hide', tooltip: 'Withdraw' }
    : { icon: 'bx bx-show', tooltip: 'Publish' };
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'publishPodcast_' + row.id} onClick={() => onPress(row)}>
        <i className={config.icon}></i>
        <UncontrolledTooltip
          placement="top"
          target={'publishPodcast_' + row.id}>
          {t(config.tooltip)}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const RescrapePodcastButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link
        to="#"
        id={'rescrapePodcast_' + row.id}
        onClick={() => onPress(row)}>
        <i className="bx bx-repost"></i>
        <UncontrolledTooltip
          placement="top"
          target={'rescrapePodcast_' + row.id}>
          {t('Rescrape Article')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const ReprocessButton = ({ row, onPress, label }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'reprocess_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-analyse"></i>
        <UncontrolledTooltip placement="top" target={'reprocess_' + row.id}>
          {t(label || 'Reprocess Article')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const InsightsButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'insights_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-bar-chart-alt-2"></i>
        <UncontrolledTooltip placement="top" target={'insights_' + row.id}>
          {t('Insights')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const AnalysisButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'analysis_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-purchase-tag-alt"></i>
        <UncontrolledTooltip placement="top" target={'analysis_' + row.id}>
          {t('Categories')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const TestQueryButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'testQuery_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-filter-alt"></i>
        <UncontrolledTooltip placement="top" target={'testQuery_' + row.id}>
          {t('Test Query')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const ActiveButton = ({ row, onPress, key = 'active' }) => {
  const { t } = useTranslation();
  const config = row[key]
    ? { icon: 'bx bx-pause-circle', tooltip: 'Disable' }
    : { icon: 'bx bx-play-circle', tooltip: 'Active' };
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'active_' + row.id} onClick={() => onPress(row)}>
        <i className={config.icon}></i>
        <UncontrolledTooltip placement="top" target={'active_' + row.id}>
          {t(config.tooltip)}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const ApproveButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'approve_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-user-check"></i>
        <UncontrolledTooltip placement="top" target={'approve_' + row.id}>
          {t('Approve')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const RejectButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'reject_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-user-x"></i>
        <UncontrolledTooltip placement="top" target={'reject_' + row.id}>
          {t('Reject')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const AdUnitButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'adUnit_' + row.id} onClick={() => onPress(row)}>
        <i className="fas fa-ad"></i>
        <UncontrolledTooltip placement="top" target={'adUnit_' + row.id}>
          {t('Create Ad Units')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const InfoButton = ({ row, onPress, label }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'info_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-info-circle"></i>
        <UncontrolledTooltip placement="top" target={'info_' + row.id}>
          {t(label || 'Info')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const PlayButton = ({ row, onPress, tooltip = 'Play' }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'play_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-play-circle"></i>
        <UncontrolledTooltip placement="top" target={'play_' + row.id}>
          {t(tooltip)}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const PauseButton = ({ row, onPress, tooltip = 'Pause' }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'pause_' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-pause-circle"></i>
        <UncontrolledTooltip placement="top" target={'pause_' + row.id}>
          {t(tooltip)}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const RemoteButton = ({ label, onPress, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="btn-rounded waves-effect waves-light mr-2 btn btn-primary"
      onClick={() => onPress()}
      disabled={disabled}>
      <i className="bx bx-cloud-download mr-1"></i>
      {t(label)}
    </button>
  );
};

export const ActivateUserButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'activate_user' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-user-check"></i>
        <UncontrolledTooltip placement="top" target={'activate_user' + row.id}>
          {t('Activate User')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const DisableUserButton = ({ row, onPress }) => {
  const { t } = useTranslation();
  return (
    <li className="list-inline-item px-1">
      <Link to="#" id={'disable_user' + row.id} onClick={() => onPress(row)}>
        <i className="bx bx-user-x"></i>
        <UncontrolledTooltip placement="top" target={'disable_user' + row.id}>
          {t('Disable User')}
        </UncontrolledTooltip>
      </Link>
    </li>
  );
};

export const ToggleColumnButton = ({
  columns,
  hiddenColumns,
  onToggleColumn,
}) => {
  const [show, setShow] = useState(false);
  columns = columns.filter(({ name, skip }) => name !== 'action' && !skip);

  return (
    <Dropdown
      isOpen={show}
      toggle={() => setShow(!show)}
      className="d-inline-block">
      <DropdownToggle className="btn header-item waves-effect" tag="button">
        <i className="bx bx-columns font-size-24"></i>
        <i
          className={`mdi mdi-chevron-${
            show ? 'up' : 'down'
          } d-none d-xl-inline-block`}></i>
      </DropdownToggle>
      <DropdownMenu right>
        <div className="mx-1">
          {columns.map(({ name, title }) => (
            <div
              key={name}
              className="form-check form-check-end"
              onClick={() => onToggleColumn(name)}>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                name={name}
                defaultChecked={!hiddenColumns.includes(name)}
              />
              <label className="form-check-label" htmlFor={name}>
                {title}
              </label>
            </div>
          ))}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export const CountryFlag = ({ row }) => {
  const [lang, country] = row.langTag.split('-');
  const language = languageTag.find(({ tag }) => row.langTag === tag).language;

  return (
    <>
      <ReactCountryFlag
        id={`flag_${country}`}
        countryCode={country}
        aria-label={languageTag}
        style={{
          fontSize: '1.75em',
        }}
      />
      <UncontrolledTooltip placement="top" target={`flag_${country}`}>
        {language}
      </UncontrolledTooltip>
    </>
  );
};
